import { ArrowForward } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { motion } from 'framer-motion'
import React, { useRef, useState } from 'react'
import { gradPurple, purple } from '../constants/colors'
import wallImg from '../assets/images/wall.png';
import Typing from 'react-typing-effect'


function Hero() {

    const scrollInto = () => {
        window.scrollTo({
            top: 500,
            behavior: 'smooth'
        })
    }

    return (
        <Stack alignItems={'center'} justifyContent={'center'} direction={{ xs: 'column', sm: 'row' }} sx={{
            px: { xs: 3, md: 10, lg: 20 },
            height: { xs: 'calc(100vh - 65px)', md: 'calc(100vh - 81px)' },
            backgroundImage: { xs: '', md: `url(${wallImg})` },
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
        }}>
            <Stack data-aos='fade-up' alignItems={'center'} spacing={2} zIndex={1} textAlign={'center'}>
                <Typography variant='h2' fontWeight={700}>
                    <span style={{ color: '#7474b4' }}>M</span >iddle <span style={{ color: '#7474b4' }}>E</span>ast <span style={{ color: '#7474b4' }}>N</span>etwork
                </Typography>
                <Typography variant='h6' fontWeight={500}>
                    We are an arabic comunity focused on <strong>Multi Theft Auto</strong><br />
                    <Typing
                        typingDelay={1000}
                        eraseSpeed={50}
                        speed={100}
                        staticText='Our main official'
                        text={['Mta server is MiddleEast RolePlay', 'Discord Server is Middle East Network']}
                    />
                </Typography>

                <Button endIcon={<ArrowForward />} variant='contained' onClick={scrollInto} sx={{
                    bgcolor: purple,
                    border: '2px solid transparent',
                    borderRadius: 50,
                    fontFamily: 'Nunito',
                    transition: '0.3s',
                    ':hover': {
                        border: `2px solid ${purple}`,
                        bgcolor: 'transparent',
                        color: purple
                    }
                }} >
                    <Typography fontWeight={700}>
                        Explore More
                    </Typography>
                </Button>

            </Stack>
        </Stack >
    )
}

export default Hero