import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'
import { Box } from '@mui/material';
import { darkPurple } from '../constants/colors';
import '../assets/styles/style.css'
import Footer from '../components/Footer';
import Aos from 'aos'
import 'aos/dist/aos.css'
import pattern2 from '../assets/images/pattern2.svg';
import Home from '../pages/Home';
import Store from '../pages/Store';
import Forum from '../pages/Forum';
import Join from '../pages/Join';
import Dev from '../pages/Dev';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from '../pages/NotFound';
import { useLocation } from 'react-router-dom'

const Helmet = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        // change document title on navigate
        if (pathname[1] !== undefined) {
            const titleToUpper = pathname[1].toUpperCase() + pathname.slice(2)
            const title = `Middle East Network - ${titleToUpper}`

            document.title = title
        } else {
            document.title = 'Middle East Network'
        }

        // scroll to the top on navigate
        window.scrollTo(0, 0)
    }, [pathname])

}

function App() {

    useEffect(() => {
        Aos.init()
    }, []);

    return (
        <BrowserRouter>
            <Helmet />
            <Box sx={{
                backgroundImage: `url(${pattern2})`,
            }}
                overflow={'hidden'}
                bgcolor={darkPurple}
                color='#fff'
                minHeight={'100vh'}
                pt={9}
            >
                <NavBar />
                <Routes >
                    <Route path='/' exact element={<Home />} />
                    <Route path='store' element={<Store />} />
                    <Route path='forum' element={<Forum />} />
                    <Route path='join' element={<Join />} />
                    <Route path='dev' element={<Dev />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
                <Footer />
            </Box>
        </BrowserRouter >
    )
}

export default App