import React from 'react'
import Hero from '../components/Hero';
import Status from '../components/Status';
import ServerCard from '../components/ServerCard';
import Join from '../components/Join';

const Home = () => {


    return (
        <>
            <Hero />
            <Status />
            <ServerCard />
            <Join />
        </>
    )
}

export default Home