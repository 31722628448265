import React, { useState } from 'react'
import { Typography, IconButton, Stack, Box } from '@mui/material'
import { Menu, } from '@mui/icons-material'
import { NavLink, Link } from 'react-router-dom'
import menLogo from '../assets/images/menLogo.png'
import { purple } from '../constants/colors'
import { navBarElements } from '../constants/navBar';

function NavBar() {


    const fullBtnStyle = {
        border: `2px solid ${purple}`,
        color: purple,
        padding: '5px 15px',
        borderRadius: 50
    }

    const fullInactiveBtnStyle = {
        border: `2px solid transparent`,
        background: purple,
        padding: '5px 15px',
        borderRadius: 50
    }

    const [IsTopPage, setIsTopPage] = useState(true);

    window.addEventListener('scroll', () => {
        window.scrollY >= 80 ? setIsTopPage(false) : setIsTopPage(true)
    })

    return (
        <Stack direction={'row'} alignItems='center' justifyContent='space-between' sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 10,
            py: 2,
            px: { xs: 3, md: 10, lg: 20 },
            transition: '.2s border',
            borderBottom: `1px solid ${!IsTopPage ? purple : 'transparent'}`,
            backdropFilter: !IsTopPage && 'blur(80px)'
        }}>
            <Link to={'/'} data-aos='fade-right'>
                <Stack spacing={2} direction={'row'}>
                    <img src={menLogo} alt="" width={40} />
                    <Typography sx={{ display: { xs: 'none', md: 'flex' } }} fontFamily={'Nunito'} fontWeight={700} variant='h5'>Middle East Network</Typography>
                </Stack>
            </Link>
            <Stack data-aos='fade-left' spacing={3} direction='row' alignItems={'center'} sx={{
                display: { xs: 'none', sm: 'flex' },
                'a': {
                    transition: '.2s'
                },
            }}>

                {navBarElements.map((element) =>
                    <NavLink end={element.end} style={({ isActive }) => {
                        if (isActive) {
                            return element.full ? fullBtnStyle : { color: purple }
                        } else {
                            return element.full ? fullInactiveBtnStyle : undefined
                        }
                    }} to={element.to}>
                        <Typography fontWeight={700}>{element.name}</Typography>
                    </NavLink>
                )}


                {/* <NavLink style={({ isActive }) => isActive ?  : primaryStyle} to={element.to}>
                            <Typography>{element.name}</Typography>
                        </NavLink> */}



            </Stack>
            <IconButton data-aos='fade-left' sx={{ color: '#fff', display: { xs: 'flex', sm: 'none' } }}>
                <Menu sx={{ fontSize: '2rem' }} />
            </IconButton>
        </Stack >
    )
}

export default NavBar