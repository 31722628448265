import { Button, Typography } from '@mui/material'
import { purple } from '../constants/colors'
import { Stack } from '@mui/system'
import React from 'react'
import gtaLogo from '../assets/images/gtaLogo.png'
import mtaLogo from '../assets/images/mtaLogo.png'

const Download = ({ gta, mta }) => {

    return (
        <Stack
            direction={{ xs: 'column-reverse', md: gta ? 'row' : 'row-reverse' }}
            alignItems={'center'}
            spacing={16}
        >
            <Stack data-aos={gta ? 'fade-up-right' : 'fade-up-left'} spacing={2}>
                <Typography variant={'h3'}>Download {gta ? 'Gta San Andreas' : 'Multi Theft Auto'}</Typography>
                <Typography >
                    You need first to download Gta San Andreast and setup it <br />
                    by clicking the button below then complete the stup
                </Typography>
                <a href={gta ? 'https://1vef.web.svpj.pl/GTASA.zip' : 'https://multitheftauto.com/'}>
                    <Button sx={{
                        bgcolor: purple,
                        borderRadius: 4,
                        width: '100%'
                    }}
                        variant='contained'
                    >
                        <Typography fontWeight={700} textTransform={'capitalize'}>Download {gta ? 'GTA:SA' : 'MTA:SA'}</Typography>
                    </Button>
                </a>
            </Stack>
            <Stack data-aos={gta ? 'fade-up-left' : 'fade-up-right'}>
                <img src={gta ? gtaLogo : mtaLogo} width={gta ? 300 : 400} />
            </Stack>

        </Stack >
    )
}

export default Download