import { Avatar, Box, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { motion } from 'framer-motion'
import React from 'react'
import { purple, darkPurple, gradPurple as gradPurple } from '../constants/colors'
import { FaDiscord, FaYoutube, FaTwitter, FaUser, FaInstagram } from 'react-icons/fa';

const StatuCard = ({ icon, num, text, bg }) => (
    <Stack borderRadius={50} bgcolor={darkPurple} py={2} px={2} direction='row' alignItems={'center'} spacing={1} sx={{ filter: `drop-shadow(8px 4px 8px ${bg})` }}>
        <Avatar sx={{ bgcolor: bg || purple, mr: 1 }} >
            {icon}
        </Avatar>
        <Typography>
            <span style={{ fontWeight: 500 }}>{num}+</span> {text}
        </Typography>
    </Stack>
)

function Status() {
    return (
        <Stack id='mtaCard' data-aos='zoom-out' sx={{
            background: gradPurple
        }}
            direction={{ xs: 'column', lg: 'row' }}
            alignItems={{ xs: 'stretch', lg: 'center' }}
            justifyContent={'space-between'}
            py={3}
            px={{ xs: 3, md: 10, lg: 20 }}
            spacing={2}
        >
            <StatuCard bg={'#5372ff'} icon={<FaDiscord />} num={'11.341'} text='Member' />
            <StatuCard bg={'#1894ff'} icon={<FaTwitter />} num={'401'} text='Follower' />
            <StatuCard bg={'#2c2c2c'} icon={<FaUser />} num={'23.100'} text='Player' />
            <StatuCard bg={'#ff3030'} icon={<FaYoutube />} num={'2.093'} text='Subscriber' />
            <StatuCard bg={'#ff2ade '} icon={<FaInstagram />} num={'6.403'} text='Follower' />
        </Stack>
    )
}

export default Status