import React from 'react'
import { Box, Typography } from '@mui/material'
import Download from '../components/Download'
import { Stack } from '@mui/system'
import { gradPurple, purple } from '../constants/colors'
import { Card } from '../components/ServerCard'
import mtaWall from '../assets/images/mta.png'

const Join = () => {
    return (

        <Stack
            minHeight={'calc(100vh - 71px)'}
            py={8}
            alignItems={'center'}
            spacing={12}
        >
            <Stack
                data-aos='zoom-out'
                sx={{
                    background: gradPurple
                }}
                bgcolor={purple}
                px={{ xs: 3, md: 10, lg: 20 }}
                width={'100%'}
                py={4}
            >
                <Typography
                    textAlign={'center'}
                    variant={'h5'}>
                    To play in our server you will need to install Multi Theft Auto, <br />
                    which requires you to have the Grand Theft Auto: San Andreas installed on your machine.<br />
                    Follow The steps below to setup everything.
                </Typography>
            </Stack>
            <Stack
                data-aos='zoom-out'
                px={{ xs: 3, md: 10, lg: 20 }}
                alignItems={'center'}
                spacing={12}
                textAlign={{ xs: 'center', md: 'unset' }}
            >
                <Download gta />
                <Typography
                    data-aos='fade-up'
                    textAlign={'center'}
                    borderBottom={`2px solid ${purple}`}
                    pb={1}
                    variant={'h5'}>
                    After downloading and Installing Gta San Andreas, you need to download Multi Theft Auto
                </Typography>
                <Download mta />
                <Typography
                    data-aos='fade-up'
                    textAlign={'center'}
                    borderBottom={`2px solid ${purple}`}
                    pb={1}
                    variant={'h5'}>
                    Enjoy!, Now you can join us in our server by clicking the join server button below
                </Typography>
                <Card right mta name={'Middle East RolePlay v7.2.0'} img={mtaWall} num={'23.100'} />
            </Stack>
        </Stack>

    )
}

export default Join