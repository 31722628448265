import React from 'react'
import { Box } from '@mui/material'

const NotFound = () => {
    return (

        <Box
            minHeight={'calc(100vh - 71px)'}
        >
            hy
        </Box>
    )
}

export default NotFound