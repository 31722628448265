import { Avatar, Divider, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React from 'react'
import { FaDiscord, FaTelegramPlane, FaTwitter, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import { Link, NavLink } from 'react-router-dom'
import { purple } from '../constants/colors'
import menLogo from '../assets/images/menLogo.png'
import { navBarElements } from '../constants/navBar'

const FooterElement = ({ title, children, logo }) => (
    <Stack p={1} spacing={2} alignItems={{ xs: 'center', md: 'unset' }} >
        <Stack direction='row' alignItems={'center'} spacing={2} >
            {logo ? <img src={menLogo} alt="" width={24} /> : ''}
            <Typography sx={{
                position: 'relative',
                pb: 1,
                '::before': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    bgcolor: purple,
                    height: '1px',
                    width: '80%'
                }
            }} noWrap> {title}</Typography>
        </Stack>
        <Typography variant='body2' color='GrayText'>{children}</Typography>
    </Stack>
)

const Footer = () => {
    return (
        <div data-aos='fade-up'>
            <Box py={3} px={{ xs: 3, md: 10, lg: 20 }} bgcolor={'#0e0e18'}>
                <Stack justifyContent={'space-between'} spacing={2} textAlign={{ xs: 'center', md: 'unset' }} alignItems={{ xs: 'center', md: 'unset' }} direction={{ xs: 'column', md: 'row' }}>
                    <FooterElement logo title='Middle East Network'>
                        We are an arabic comunity focused on Multi Theft Auto <br />
                        A Saudi Arab institution interested in developing the experience of the Arab player <br />
                        by providing servers on various gaming platforms
                    </FooterElement>
                    <FooterElement title='Nav Links' >
                        <Stack sx={{ 'a:hover': { color: purple } }} spacing={1}>
                            {navBarElements.map(element =>
                                <NavLink end={element.end} style={({ isActive }) => isActive ? { color: purple } : undefined} to={element.to}>
                                    {element.name}
                                </NavLink>
                            )}
                        </Stack>
                    </FooterElement>
                    <FooterElement title='Social Media Accounts' >
                        <Stack sx={{ 'a': { display: 'flex', gap: 1 }, 'a:hover': { color: purple } }} spacing={1}>
                            <a target="_blank" href='https://discord.gg/men'><FaDiscord />Discord</a>
                            <a target="_blank" href='https://twitter.com/MiddleEast_SA'><FaTwitter />Twitter</a>
                            <a target="_blank" href='https://t.me/mec_c'><FaTelegramPlane />Telegram</a>
                            <a target="_blank" href='https://www.youtube.com/channel/UC_32GeQbBcHBSTbd3_GMhEg'><FaYoutube />Youtube</a>
                            <a target="_blank" href='https://chat.whatsapp.com/FyX2g9fbVV5I1RSYypsltA'><FaWhatsapp />Whatsapp</a>
                        </Stack>
                    </FooterElement>
                </Stack>
            </Box>
            <Typography
                sx={{ '& a': { color: purple } }}
                color='#777'
                bgcolor={'#07070c'}
                textAlign={'center'}
                p={2}
                px={3}
            >
                <span>© Copyright 2022 <a href='https://discord.gg/men' target={'_blank'}>Middle East Network</a> | Powered By <Link to='dev' color={purple}>MEN Devlopement Team</Link></span>
            </Typography>
        </div >
    )
}

export default Footer