import { Box, Grid, Paper, Typography } from '@mui/material'
import { purple } from '../constants/colors'
import { Stack } from '@mui/system'
import React from 'react'
import forumSvg from '../assets/images/forum.svg'
import Btn from '../components/Btn'

const Styles = {
    bordred: {
        position: 'relative',
        pb: 1,
        '::before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            bgcolor: purple,
            height: '2px',
            width: '100%',
        }
    }
}

const Store = () => {
    return (
        <Stack
            minHeight={'calc(100vh - 71px)'}
            px={{ xs: 3, md: 10, lg: 20 }}
            py={8}
            direction={{ xs: 'column-reverse', lg: 'row' }}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={{ xs: 4, md: 10 }}
        >
            <Stack data-aos='zoom-out-right' >
                <Stack spacing={2} textAlign={{ xs: 'center', md: 'unset' }}>
                    <Typography sx={Styles.bordred} variant='h3'>Our Official Forum</Typography>
                    <Typography variant='caption2'>
                        This is our official forum, come talk with us <br />
                        we announce there some special announces, and more..
                    </Typography>
                    <a href='https://men.sa/omg/' target={'_blank'}>
                        <Btn >Visit Forum</Btn>
                    </a>
                </Stack>
            </Stack>
            <Stack data-aos='zoom-out-left' display='flex' justifyContent={'center'} >
                <Box width={{ xs: 0, md: 350, lg: 450 }}>
                    <img src={forumSvg} alt="" />
                </Box>
            </Stack>
        </Stack>
    )
}

export default Store