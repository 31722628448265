import { Divider, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { darkPurple, gradPurple, gradPurple2, purple } from '../constants/colors'

const tRight = {
    translate: '-50% 10px'
}

const DevCard = ({ name, role }) => (
    <Stack
        direction='row'
        divider={<Divider sx={{ bgcolor: purple }} orientation="vertical" flexItem />}
        spacing={2}
        boxShadow='0 0 12px rgba(0,0,0,0.500)'
        borderRadius={4}
        p={2}
        sx={{
            background: gradPurple2,
        }}
    >
        <Stack>
            <Typography ><b>{name}</b></Typography>
        </Stack>
        <Stack>
            <Typography ><b>{role}</b></Typography>
        </Stack>
    </Stack >
)
const Dev = () => {
    return (
        <Stack
            minHeight={'calc(100vh - 71px)'}
            alignItems='center'
            justifyContent={'center'}
            py={8}
            px={{ xs: 3, md: 10, lg: 20 }}
        >
            <Stack
                spacing={4}
                data-aos='zoom-out'
            >
                <Typography pb={2} width={'fit-content'} variant='h2' borderBottom={`2px solid ${purple}`}>
                    Our Developement Team
                </Typography>
                <Stack
                    spacing={2}
                >
                    <DevCard name='Nasser' role='Systems Analyste' />
                    <DevCard name='Salman' role='Discord Bots & MTA developer & Web Developer' />
                    <DevCard name='Omar' role='Discord Bots & MTA developer' />
                    <DevCard name='Adam' role='Web Developer' />
                    <DevCard name='Haxardous' role='MTA Developer' />
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Dev