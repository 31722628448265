import { Button, Divider, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useRef, useState } from 'react'
import { darkPurple, purple } from '../constants/colors'
import { FaDiscord, FaSignInAlt, FaUser } from 'react-icons/fa';
import { LoadingButton } from '@mui/lab';
import mtaWall from '../assets/images/mta.png';
import menDis from '../assets/images/menDis.png';

export const Card = ({ name, num, img, mta, dis, left, right }) => {

    const joinMta = () => {
        window.open('mtasa://94.23.68.73:22003', '_self')
    }

    return (
        <Stack data-aos={left ? 'zoom-out-left' : 'zoom-out-right'} spacing={1} width={{ xs: '100%', md: '50%' }} bgcolor={purple} p={2} borderRadius={4}
            sx={{
                boxShadow: `8px 4px 12px rgba(0,0,0,0.30)`,
            }}

        >
            <Stack alignItems={'center'} >
                <Typography variant={'subtitle1'} fontWeight={700}>{dis ? 'Discord Server' : 'Multi Theft Auto Server'}</Typography>
            </Stack>
            <Divider sx={{ bgcolor: darkPurple }} />
            <Stack direction={'row'} alignItems='center' justifyContent={'space-between'}>
                <Typography variant={'h6'} fontWeight={700}>
                    {name}
                </Typography>
                <Stack sx={{ fontSize: { xs: '.7rem', sm: '1rem' } }} direction={'row'} alignItems='center' spacing={1}>
                    <span>{num}</span> <FaUser />
                </Stack>
            </Stack>
            <Box sx={{
                ':hover img': {
                    scale: '1.2'
                }
            }}>
                <Stack sx={{
                    overflow: 'hidden',
                    borderRadius: 4,
                    'img': {
                        transition: '.3s',
                    },
                }}>
                    <img src={img} />
                </Stack>
                <Stack direction='row' spacing={1} justifyContent={'end'} position='absolute' bottom={28} right={28}>
                    <Button data-aos='fade-in-right' target='_blank' href={'https://discord.gg/men'} startIcon={<FaDiscord />} variant='contained' sx={{ borderRadius: 2, bgcolor: '#5372ff', display: dis ? 'flex' : 'none' }}>Join Server</Button>
                    <Button data-aos='fade-in-left' onClick={() => joinMta()} startIcon={<FaSignInAlt size={'1.1rem'} />} variant='contained' sx={{ borderRadius: 2, bgcolor: darkPurple, display: mta ? 'flex' : 'none' }}>Join Server</Button>
                </Stack >
            </Box>

        </Stack >
    )
}

const ServerCard = () => {


    return (
        <Stack spacing={3} alignItems={'center'} px={{ xs: 3, md: 10, lg: 20 }} py={8}>
            <Typography data-aos='fade-down' pb={2} borderBottom={'1px solid ' + purple} variant={'h4'}>Our Official Servers</Typography>
            <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}  >
                <Card right mta name={'Middle East RolePlay v7.2.0'} img={mtaWall} num={'23.100'} />
                <Card left dis name={'Middle East Network'} img={menDis} num={'11.341'} />
            </Stack>
        </Stack >

    )
}

export default ServerCard