import { Box, Typography } from '@mui/material'
import { purple } from '../constants/colors'
import { Stack } from '@mui/system'
import React from 'react'
import storeSvg from '../assets/images/store.svg'
import Btn from '../components/Btn'

const Styles = {
    bordred: {
        position: 'relative',
        pb: 1,
        '::before': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            bgcolor: purple,
            height: '2px',
            width: '100%',
        }
    }
}

const Store = () => {
    return (
        <Stack
            minHeight={'calc(100vh - 71px)'}
            px={{ xs: 3, md: 10, lg: 20 }}
            py={8}
            direction={{ xs: 'column-reverse', lg: 'row' }}
            alignItems={'center'}
            justifyContent={'center'}
            spacing={{ xs: 4, md: 8 }}
        >
            <Stack data-aos='zoom-out-right' >
                <Stack spacing={2} textAlign={{ xs: 'center', md: 'unset' }}>
                    <Typography sx={Styles.bordred} variant='h3'>Our Official Store</Typography>
                    <Typography variant='caption2'>
                        This is our official web store, we sell everyting related to our <br />
                        MTA & Disord Server, And other.. <br />
                        ex: Special <b>ID</b>, <b>Cars</b>, <b>Roles</b>, <b>Services</b>..
                    </Typography>
                    <a href='https://store.men.sa/' target={'_blank'}>
                        <Btn >Visit Store</Btn>
                    </a>
                </Stack>
            </Stack>
            <Stack data-aos='zoom-out-left' display='flex' justifyContent={'center'} >
                <Box width={{ xs: 0, md: 400, lg: 600 }}>
                    <img src={storeSvg} alt="" />
                </Box>
            </Stack>
        </Stack>
    )
}

export default Store