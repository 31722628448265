import { ArrowForward } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import mtaLogo from '../assets/images/mtaLogo.png'
import { purple } from '../constants/colors'

const Join = () => {
    return (
        <Stack spacing={{ xs: 5, md: 0 }} px={{ xs: 3, md: 10, lg: 20 }} py={8} pb={16} justifyContent={'center'} alignItems={'center'} direction={{ xs: 'column-reverse', md: 'row' }}>
            <Stack spacing={2} data-aos='fade-right' textAlign={{ xs: 'center', md: 'unset' }}>
                <Typography variant={'h3'}>How To Join Server</Typography>
                <Typography variant={'subtitle1'} color={'#ddd'}>
                    To play in our mta server you will need to install Some things <br />
                    like Mta and Gta .., click the button below for more informations
                </Typography>
                <Link to='/join'>
                    <Button data-aos='zoom-out-up' sx={{ width: '100%', px: 2, bgcolor: purple, borderRadius: 50 }} endIcon={<ArrowForward />} variant={'contained'}>
                        <Typography fontWeight={700} textTransform={'capitalize'}>Join Us</Typography>
                    </Button>
                </Link>
            </Stack>
            <Box alignItems={'center'}>
                <img data-aos='fade-left' src={mtaLogo} alt="" />
            </Box>
        </Stack>
    )
}

export default Join