import { Button, Typography } from '@mui/material'
import React from 'react'
import { purple } from '../constants/colors'

const Btn = ({ children }) => {
    return (
        <Button sx={{ px: 4, bgcolor: purple, borderRadius: 50, textTransform: 'capitalize' }} variant={'contained'}>
            <Typography fontWeight={700}>{children}</Typography>
        </Button>
    )
}

export default Btn