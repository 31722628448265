export const navBarElements = [
    {
        name: 'Home',
        to: '/',
        end: true
    },
    {
        name: 'Store',
        to: 'store',
    },
    {
        name: 'Forum',
        to: 'forum',
    },
    {
        name: 'Join us',
        to: 'join',
        full: true
    }
]